<template>
  <b-card
    no-body
    class="user-target-ad-collapse"
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header w-100 d-md-col">
        <span class="lead collapse-title w-20">
          <b-media class="media">
            <div class="nameClass">
              <b-link>
                <span class="d-block font-weight-bolder text-nowrap">
                  {{ title }}
                </span>
              </b-link>
            </div>
          </b-media>
        </span>
        <div
          class=" time w-20 w-md-50"
        >
          <b-img
            size="16px"
            :src="require('@/assets/images/simiicons/wall-clock.svg')"
          />
          {{ time | moment }}
        </div>
        <div class="d-flex w-20 w-md-50">
          <div v-if="approvalSpinner[id]">
            <div
              style="width: 75px;"
              class="spinner text-center"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
          </div>
          <div v-else>
            <div
              v-if="approved !== null"
              id="btn-class"
              @click="disableCollapse($event)"
            >
              <b-button
                disabled
              >
                <span class="font-small-2">Approved</span>
              </b-button>
            </div>
            <div v-else>
              <b-button
                variant="primary"
                @click="approveTargetAdsFunc($event)"
              >
                <span class="font-small-2">Approve</span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="w-40 w-md-50 review d-flex justify-content-start align-items-end justify-content-md-end align-content-md-start">
          <feather-icon
            :id="id"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            style="margin: 8px"
            @click="review($event)"
          />
        </div>
      </slot>
    </b-card-header>
    <div v-if="visible">
      <div class="device">
        Parameters
      </div>
    </div>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0px !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BMedia,
  BLink,
  BImg,
  BSpinner,
  VBToggle,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'
import moment from 'moment'

Vue.prototype.moment = moment
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BMedia,
    BLink,
    BImg,
    BSpinner,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    title: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    targetAd: {
      type: Object,
      required: true,
    },
    approved: {
      type: Object,
    },
  },
  data() {
    return {
      allowTime: false,
      approvalSpinner: {},
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      // validation
      required,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    async approveTargetAdsFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      Vue.set(this.approvalSpinner, `${this.id}`, true)
      try {
        await this.$axios
          .put(`admin/approve-target-ad/${this.id}`)
        await this.$emit('updateSwitchState')
        Vue.set(this.approvalSpinner, `${this.id}`, false)
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message)
      }
    },
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    disableCollapse(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    async review(e) {
      e.preventDefault()
      e.stopPropagation()
      const allMedia = this.targetAd.target_ads_has_media.map(media => media.media)
      if (allMedia) {
        await this.$store.dispatch('lightBox/lightBoxData', allMedia)
      }
    },
  },
}
</script>
<style lang="scss">
.user-target-ad-collapse {
  .switches {
    border-radius: 25px;
  }

  .device {
    font-size: 14px;
    letter-spacing: 0px;
    color: #414141;
    font-weight: 600;
    font-family: "Montserrat";
    padding-left: 15px;
  }

  .time {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 21px;
    color: #414141;
    font-weight: 500;
    font-family: "Montserrat";
    min-width: 240px;
  }

  .nameClass {
    min-width: 100px;
  }

  #btn-class {
    .btn-secondary {
      background-color: #00E265 !important;
      border-color: #00E265 !important;
      color: black !important;
    }
  }

  .disableIcon {
    color: #80808080;
  }

  .review {
    min-width: 150px;
  }
}
</style>
