<template>
  <div class="user-target-ads">
    <div class="d-flex justify-content-end mt-0" />
    <div>
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="paginationData.perPage"
            dir="ltr"
            :options="[3, 5, 8, 10]"
            :clearable="false"
            label="10"
            class="per-page-selector d-inline-block mx-50"
            @input="paginationChanged(paginationData.currentPage = 1)"
          />
          <label>Target Ads</label>
        </b-col>
        <b-col
          cols="6"
          sm="3"
          class="d-flex align-items-center justify-content-start justify-content-sm-end mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-start justify-content-sm-end">
            <div
              v-if="spinner"
              style="width: 75px;"
              class="spinner text-center"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
            <vs-switch
              v-else
              v-model="currentState"
              circle
              class="switches"
              :style="`background-color: ${currentState ? '#00E265' : '#FF0000'};color:white;`"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Future</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Past</span>
            </vs-switch>
          </div>
        </b-col>
        <b-col
          cols="6"
          sm="3"
          class="d-flex align-items-center justify-content-start justify-content-sm-center mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-start justify-content-sm-center">
            <div
              v-if="spinnerApproval"
              style="width: 75px;"
              class="spinner text-center"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
            <vs-switch
              v-else
              v-model="currentStatus"
              circle
              class="switches"
              :style="`background-color: ${currentStatus ? '#00E265' : '#FF0000'};color:white;`"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Approved</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Pending</span>
            </vs-switch>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <app-collapse
        id="faq-payment-qna"
        accordion
        type="margin"
        class="mt-2"
      >
        <b-skeleton-wrapper :loading="userTargetAdsLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <UserTargetAdsCollapseItem
            v-for="(targetAds, index) in userAllTargetAds"
            :id="targetAds.id"
            :key="index"
            :approved="targetAds.approved_by"
            :title="targetAds.name"
            :time="targetAds.time"
            :target-ad="targetAds"
            :src="null"
            @updateSwitchState="updateSwitchState"
          >
            <div class="heading-elements">
              <b-row>
                <b-col md="8">
                  <b-card class="ml-1 pr-0 mb-1 media-card">
                    <b-table
                      ref="mediaTable"
                      :items="targetAds.target_ads_has_media"
                      sticky-header="220px"
                      responsive
                      selectable
                      :select-mode="selectMode"
                      :fields="tableColumns"
                      show-empty
                      class="float: right"
                      th-class="text"
                      @row-selected="onRowSelected($event, index)"
                    >
                      {{ targetAds.approved_by }}

                      <!-- Column: Media -->
                      <template #cell(MEDIA)="data">
                        <b-media no-body>
                          <span class="mr-2 ml-0">
                            {{ data.index + 1 }}
                          </span>
                          <b-media-aside class="mr-1 ml-0">
                            <b-img
                              :src="getThumbnailOfFile(data.item.media)"
                              class="image"
                              rounded
                              alt="data.json"
                            />
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <b-link>
                              <span class="media-body">
                                {{ data.item.media.name }}
                              </span>
                            </b-link>
                          </b-media-body>
                        </b-media>
                      </template>
                      <!-- Column: Duration -->
                      <template
                        #cell(DURATION)="data"
                        class="BannerMode"
                      >
                        <span class="text-nowrap">
                          {{ data.item.media.duration }}
                        </span>
                      </template>
                      <!-- Column: Type -->
                      <template
                        #cell(TYPE)="data"
                        class="BannerMode center"
                      >
                        <div style="text-align: center">
                          <b-img
                            width="30px"
                            height="30px"
                            style="padding: 7px"
                            :src="data.item.media.type_main === MediaType.IMAGE ? require('@/assets/images/simiicons/Image.svg') : require('@/assets/images/simiicons/Video.svg')"
                          />
                          <span class="text-nowrap">
                            {{ data.item.media.type }}
                          </span>
                        </div>
                      </template>
                    </b-table>
                  </b-card>
                </b-col>
                <b-col md="4">
                  <b-card class="mr-1 mb-1 adds-views-class">
                    <div class="d-block font-weight-bolder text-nowrap stats">
                      Adds Views
                    </div>
                    <div class="demo-vertical-spacing">
                      <div
                        v-for="view in addsViewsData"
                        :key="view.name"
                        class="stats-div"
                      >
                        <label class="labels">{{ view.name }}</label>
                        <label class="float-right labels">{{ view.duration }}</label>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </UserTargetAdsCollapseItem>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
    <div class="mt-1">
      <div class="text-nowrap">
        <!-- pagination -->
        <b-row>
          <b-col
            cols="12"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              :value="paginationData.currentPage"
              :total-rows="paginationData.totalRecords"
              :per-page="paginationData.perPage"
              hide-goto-end-buttons
              class="mb-0 mt-1 mt-sm-0"
              aria-controls="devices-table"
              size="sm"
              pills
              @change="paginationChanged"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BMedia, BLink, BTable, BImg, BMediaBody, BMediaAside, BSkeletonWrapper, BSkeleton, VBTooltip, BRow, BCol, BCard, BSpinner, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import vSelect from 'vue-select'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import UserTargetAdsCollapseItem from '@/components/admin/UserTargetAdsCollapseItem.vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  name: 'Index',
  components: {
    UserTargetAdsCollapseItem,
    AppCollapse,
    BTable,
    BMedia,
    BLink,
    BImg,
    BMediaBody,
    BMediaAside,
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BPagination,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      paginationData: {
        currentPage: 1,
        perPage: 5,
        totalRecords: 0,
      },
      currentState: false,
      timeState: 'future',
      approvalState: 'approved',
      currentStatus: false,
      spinner: false,
      spinnerApproval: false,
      MediaType,
      active: false,
      userTargetAdsLoading: false,
      id: '',
      campaign: '',
      userAllTargetAds: [],
      selectMode: 'single',
      tableColumns: [{ key: 'MEDIA' }, { key: 'DURATION' }, { key: 'TYPE', thClass: 'text-center' }],
      tableRefIndex: -1,
      addsViewsData: [{ name: 'views', duration: '0.30' }, { name: 'complete views', duration: '0.30' }, { name: 'reports', duration: '0.30' }, { name: 'cost per view', duration: '0.30' }],
    }
  },
  watch: {
    currentState() {
      if (this.currentState === true) {
        this.timeState = 'past'
      } else {
        this.timeState = 'future'
      }
      this.paginationData.currentPage = 1
      this.updateSwitchState()
    },
    currentStatus() {
      if (this.currentStatus === true) {
        this.approvalState = 'pending'
      } else {
        this.approvalState = 'approved'
      }
      this.paginationData.currentPage = 1
      this.updateSwitchState()
    },
  },
  mounted() {
    this.getAllUserTargetAds(this.timeState, this.approvalState)
  },
  methods: {
    async paginationChanged(offset) {
      this.paginationData.currentPage = offset
      await this.updateSwitchState()
    },
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    getAllUserTargetAds(state, status) {
      this.userTargetAdsLoading = true
      this.$axios
        .get(`admin/get-all-target-ads?offset=${this.paginationData.currentPage - 1}&limit=${this.paginationData.perPage}&search_query=${state}&status=${status}`)
        .then(({ data: { data } }) => {
          if (this.currentPage > 1 && data.results.length === 0) {
            this.paginationChanged(this.currentPage - 1)
            return
          }
          this.userAllTargetAds = data.results
          this.paginationData.totalRecords = data.total
          this.userTargetAdsLoading = false
        }).catch(error => ({ error }))
    },
    async onRowSelected(items, index) {
      if (items[0] && items[0].media) {
        const mediaArray = Object.assign([], [items[0].media])
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      await this.$refs.mediaTable[index].clearSelected()
    },
    async updateSwitchState() {
      this.spinner = true
      await this.getAllUserTargetAds(this.timeState, this.approvalState)
      this.spinner = false
    },
  },

}
</script>

<style lang="scss">

.user-target-ads{
  .switches {
    border-radius: 25px;
  }
  .image {
    width: 32px;
    height: 32px;
    border: 2px solid#ececec;
    border-radius: 5px !important;
    box-shadow: 0 1px 7px#0000002e;
  }
  .demo-vertical-spacing{
    margin-bottom: 5px;
  }
  .labels{
    font-size: 12px;
    letter-spacing: 0px;
    color: #414141;
    font-weight: 500;
    font-family: "Montserrat";
  }
  .stats-div{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .stats{
    font-size: 14px;
    letter-spacing: 0px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }
  //.media-card{
  //  max-height: 225px;
  //  overflow:auto
  //}
  .adds-views-class{
    min-height: 225px;
  }
  .per-page-selector {
    width: 90px;
  }
}
</style>
